import { Stack } from '@mui/material'
import { useScrollDirection } from 'hooks'
import React, { FC } from 'react'

import { Props } from './StickyNavigation.types'
export const StickyNavigation: FC<React.PropsWithChildren<Props>> = ({
  margin = '-24px 0',
  children,
}) => {
  const scrollDirection = useScrollDirection()

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={`${scrollDirection === 'down' ? 'sticky' : ''}`}
      sx={{
        boxShadow: 'none',
        top: '72px',
        zIndex: 99,
        transition: 'top 0.5s',
        position: 'sticky',
        bgcolor: 'white',
        py: {
          xs: '12px',
          sm: '24px',
        },
        margin,
        '&:before': {
          width: 'calc(100% + 20px)',
          height: '100%',
          content: `' '`,
          position: 'absolute',
          top: '0',
          left: '-10px',
          bgcolor: 'white',
          zIndex: '-1',
        },
        '&.sticky': {
          top: 0,
          transition: 'top 0.5s',
        },
      }}
    >
      {children}
    </Stack>
  )
}
