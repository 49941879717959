import { Stack } from '@mui/material'
import { ChipButton } from 'components/ChipButton'
import { ClearIcon } from 'icons'
import React, { FC } from 'react'

import { Props } from './FilteredItems.types'

export const FilteredItems: FC<Props> = ({ items, clearClick }) => {
  return (
    <Stack
      margin={'24px 0 -24px'}
      flexWrap={'wrap'}
      spacing={'8px'}
      direction={'row'}
    >
      {items.map(({ title }) => (
        <ChipButton
          key={title}
          Icon={
            <ClearIcon
              width={18}
              height={18}
              viewBox='0 0 24 24'
              color='rgba(34, 34, 34, 1)'
            />
          }
          onClick={() => clearClick(title as string)}
        >
          {title}
        </ChipButton>
      ))}
    </Stack>
  )
}
