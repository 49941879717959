import { FormControl, Select, Skeleton } from '@mui/material'
import { SimpleArrowDownwardIcon } from 'icons'
import React, { forwardRef } from 'react'

import { Props } from './SelectOutlined.types'

export const SelectOutlined = forwardRef<
  HTMLInputElement,
  React.PropsWithoutRef<Props>
>(({ children, loading, withMenuRight, ...restProps }, ref) => {
  return loading ? (
    <Skeleton
      variant={'rectangular'}
      width={'133px'}
      sx={{ borderRadius: '24px', height: '48px' }}
    />
  ) : (
    <FormControl
      sx={{
        maxWidth: 'inherit',
        '& .MuiFormLabel-root': {
          '&.MuiInputLabel-shrink': {
            fontSize: '12px',
            lineHeight: '18px',
          },
        },
        '.MuiSelect-iconOpen': {
          transform: 'none',
          path: {
            fill: '#009970',
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'grey.200',
        },

        '& .MuiOutlinedInput-root': {
          borderRadius: '24px',
          pl: 0,
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'grey.300',
            },
          },
          '&:active': {
            '.MuiOutlinedInput-notchedOutline': {
              backgroundColor: 'grey.50',
            },
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'grey.300',
              borderWidth: '1px',
            },
          },
        },
      }}
    >
      <Select
        inputRef={ref}
        IconComponent={props => {
          return (
            <i {...props} style={{ height: '20px', right: '13px' }}>
              <SimpleArrowDownwardIcon
                width={20}
                height={20}
                viewBox='0 0 24 24'
                color='rgba(34, 34, 34, 1)'
              />
            </i>
          )
        }}
        displayEmpty
        autoWidth
        inputProps={{
          'aria-label': 'Without label',
          sx: {
            padding: '13px 38px 13px 24px !important',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '&.MuiPaper-root': {
                boxShadow:
                  '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 8px 18px rgba(34, 34, 34, 0.1)',
                borderRadius: '12px',
                marginTop: '12px',
              },
              '& .MuiList-root': {
                minWidth: 120,
              },
              '& .MuiMenuItem-root': {
                padding: '12px 48px 12px 24px',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(34, 34, 34, 0.04)',
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(34, 34, 34, 0.04)',
                  },
                  '&::after': {
                    content: `' '`,
                    position: 'absolute',
                    right: 33,
                    width: 7,
                    height: 15,
                    borderBottom: '2px solid rgba(0, 153, 112, 1)',
                    borderRight: '2px solid rgba(0, 153, 112, 1)',
                    transform: 'rotate(45deg) translateX(5px) translateY(-8px)',
                  },
                },
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: withMenuRight ? 'right' : 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: withMenuRight ? 'right' : 'left',
          },
        }}
        {...restProps}
      >
        {children}
      </Select>
    </FormControl>
  )
})
