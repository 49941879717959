import { Box, MenuItem, Stack } from '@mui/material'
import { GetAuctionListData } from 'api/requests/auction'
import { AuctionCardsView } from 'components/AuctionCardsView'
import { FilteredItems } from 'components/FilteredItems'
import { Filters } from 'components/Filters'
import { HeadingWithCounter } from 'components/HeadingWithCounter'
import { NoResults } from 'components/NoResults'
import { ItemPagination } from 'components/Pagination'
import { SearchComponent } from 'components/SearchComponent'
import { SelectOutlined } from 'components/SelectOutlined'
import { AuctionsSkeleton } from 'components/Skeleton/auctions'
import { StickyNavigation } from 'components/StickyNavigation'
import { ToggleViewButton } from 'components/ToggleViewButton'
import {
  useBuyerAuctions,
  useCategories,
  useLocations,
  useManufacturers,
} from 'hooks'
import { useAuctionLotSizes } from 'hooks/useAuctionLotSizes'
import { useConditions } from 'hooks/useConditions'
import { useStatuses } from 'hooks/useStatuses'
import { MainLayout } from 'layouts/MainLayout'
import { debounce } from 'lodash'
import { NextPageWithLayout } from 'next'
import { useRouter } from 'next/router'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { ViewType } from 'types/shared'
import { smoothScroll } from 'utils/scroll'

const Auctions: NextPageWithLayout = () => {
  const router = useRouter()
  const query = router?.query
  const { searchParam } = query
  const perPage = 9

  const {
    buyerData,
    total,
    count,
    search,
    sort,
    notEmpty,
    setSearch,
    fetchAuctionList,
    handleChange,
    handleSearchChange,
    handleClearSearchClick,
  } = useBuyerAuctions(perPage, true)

  const [view, setView] = useState<ViewType>('grid')
  const [page, setPage] = useState<number>(1)
  const [isQuerySet, setIsQuerySet] = useState(false)

  const handleChangeView = (
    _: MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      setView(newView as ViewType)
    }
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  const onResetPage = () => {
    smoothScroll()
    setPage(1)
  }

  const {
    categories,
    fetchCategories,
    favoriteCategoriesCopy,
    handleCategorySelect,
    handleSubCategorySelect,
    setFavoriteCategoriesCopy,
    handleRestCategoriesSelect,
  } = useCategories(true)

  const {
    locations,
    fetchLocations,
    favoriteLocations,
    handleLocationSelect,
    setFavoriteLocations,
  } = useLocations(true)

  const {
    manufacturers,
    fetchManufacturers,
    favoriteManufacturers,
    handleManufacturerSelect,
    setFavoriteManufacturers,
    handleRestManufacturersSelect,
  } = useManufacturers(true)

  const {
    conditions,
    fetchConditions,
    favoriteConditions,
    setFavoriteConditions,
    handleConditionsSelect,
    handleConditionSelect,
  } = useConditions(true)

  const {
    statuses,
    fetchStatuses,
    favoriteStatuses,
    setFavoriteStatuses,
    handleStatusSelect,
  } = useStatuses(true)

  const {
    lotSizes,
    fetchLotSizes,
    favoriteLotSizes,
    handleLotSizesSelect,
    handleRestLotSizesSelect,
    setFavoriteLotSizes,
  } = useAuctionLotSizes(true)

  const debouncedSearch = useCallback(debounce(fetchAuctionList, 500), [])

  useEffect(() => {
    if (!isQuerySet && query?.sellerId) {
      setFavoriteStatuses(['active'])
      setIsQuerySet(true)
    }
  }, [isQuerySet, query])

  useEffect(() => {
    const requestQuery: GetAuctionListData = { perPage, page }

    if (query?.sellerId) {
      requestQuery.sellerId = Number(query.sellerId)
    }
    if (favoriteCategoriesCopy?.length) {
      requestQuery.categories = favoriteCategoriesCopy
    }
    if (favoriteLotSizes?.length) {
      requestQuery.lotSizes = favoriteLotSizes
    }
    if (favoriteLocations?.length) {
      requestQuery.locations = favoriteLocations
    }
    if (favoriteManufacturers?.length) {
      requestQuery.manufacturers = favoriteManufacturers
    }
    if (favoriteConditions?.length) {
      requestQuery.conditions = favoriteConditions
    }
    if (favoriteStatuses?.length) {
      requestQuery.statuses = favoriteStatuses
    }
    if (search?.length) {
      requestQuery.search = search
    }
    if (sort) {
      requestQuery.sort = sort
    }
    void debouncedSearch(requestQuery)
  }, [
    page,
    query,
    favoriteCategoriesCopy,
    favoriteLocations,
    favoriteManufacturers,
    favoriteConditions,
    favoriteStatuses,
    favoriteLotSizes,
    search,
    sort,
    isQuerySet,
  ])

  useEffect(() => {
    void fetchCategories()
    void fetchLocations()
    void fetchManufacturers()
    void fetchConditions()
    void fetchStatuses()
    void fetchLotSizes()
  }, [])

  useEffect(() => {
    if (!search?.length && searchParam?.length) {
      setSearch(searchParam as string)
    }
  }, [searchParam])

  const handleClearAllFilters = async () => {
    await router.push('/auctions')
    setFavoriteManufacturers([])
    setFavoriteLocations([])
    setFavoriteCategoriesCopy([])
    setFavoriteConditions([])
    setFavoriteStatuses([])
    setSearch('')
  }

  const isWithFilters =
    Object.keys(query).length > 0 ||
    !!favoriteCategoriesCopy?.length ||
    !!favoriteLocations?.length ||
    !!favoriteManufacturers?.length ||
    !!favoriteConditions?.length ||
    !!favoriteStatuses?.length ||
    !!search?.length ||
    !!favoriteLotSizes?.length

  const selectedFilters = [
    { selected: favoriteManufacturers.length, title: 'Manufacturer' },
    { selected: favoriteLocations.length, title: 'Location' },
    { selected: favoriteConditions.length, title: 'Condition' },
    { selected: favoriteStatuses.length, title: 'Status' },
    { selected: favoriteCategoriesCopy.length, title: 'Category' },
    { selected: favoriteLotSizes.length, title: 'Lot size' },
  ]

  const filteredTitles = selectedFilters.filter(arr => arr.selected > 0)

  const clearClickHandler = (title: string) => {
    if (title === 'Manufacturer') {
      return setFavoriteManufacturers([])
    }
    if (title === 'Location') {
      setFavoriteLocations([])
    }
    if (title === 'Condition') {
      setFavoriteConditions([])
    }
    if (title === 'Status') {
      setFavoriteStatuses([])
    }
    if (title === 'Category') {
      setFavoriteCategoriesCopy([])
    }
    if (title === 'Lot size') {
      setFavoriteLotSizes([])
    }
  }

  const isLoading = !buyerData
  const isDataNotEmpty = buyerData?.data.length
  return isLoading ? (
    <AuctionsSkeleton />
  ) : (
    <>
      <StickyNavigation>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          flex={1}
          alignItems={'center'}
        >
          <Box
            sx={{
              width: {
                xs: 'auto',
                md: '23.2%',
              },
              mr: '2.2%',
            }}
          >
            <HeadingWithCounter
              title='Auctions'
              count={total}
              onActiveButtonClick={handleClearAllFilters}
              isActive={isWithFilters}
            />
          </Box>
          <Stack
            direction={'row'}
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: '1012px',
              flex: 1,
            }}
          >
            <SearchComponent
              width={'670px'}
              placeholder={'Search auction by ID, lot ID, or lot title'}
              value={search}
              onChange={handleSearchChange}
              onClearClick={handleClearSearchClick}
            />
            <Stack direction={'row'} spacing={'12px'}>
              <SelectOutlined value={sort} onChange={handleChange}>
                <MenuItem value={'mostPopular'}>Most popular</MenuItem>
                <MenuItem value={'endingSoon'}>Ending soon</MenuItem>
                <MenuItem value={'newest'}>Newest</MenuItem>
              </SelectOutlined>
              <ToggleViewButton view={view} onChange={handleChangeView} />
            </Stack>
          </Stack>
        </Stack>
      </StickyNavigation>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '23.2%',
            mr: '2.2%',
          }}
        >
          <Filters
            isShowMore
            withDisabled
            withAdditionalValue
            categories={categories}
            locations={locations}
            lotSizes={lotSizes}
            manufacturers={manufacturers}
            conditions={conditions}
            statuses={statuses}
            favoriteCategories={favoriteCategoriesCopy}
            favoriteLocations={favoriteLocations}
            favoriteManufacturers={favoriteManufacturers}
            favoriteConditions={favoriteConditions}
            favoriteStatuses={favoriteStatuses}
            favoriteLotSizes={favoriteLotSizes}
            onLotSizesSelect={handleLotSizesSelect}
            onCategorySelect={handleCategorySelect}
            onConditionsSelect={handleConditionSelect}
            onSubCategorySelect={handleSubCategorySelect}
            onLocationsSelect={handleLocationSelect}
            onManufacturersSelect={handleManufacturerSelect}
            onStatusesSelect={handleStatusSelect}
            onRestConditionsSelect={handleConditionsSelect}
            onRestCategoriesSelect={handleRestCategoriesSelect}
            onRestManufacturersSelect={handleRestManufacturersSelect}
            onRestLotSizesSelect={handleRestLotSizesSelect}
            onResetPage={onResetPage}
            isSorted={false}
          />
        </Box>
        {notEmpty && !isDataNotEmpty ? (
          <>
            <NoResults
              title={'No results to display'}
              description={
                'Please try another search request or filter options.'
              }
              buttonTitle={'Show all'}
              onClick={handleClearAllFilters}
            />
          </>
        ) : (
          <Stack
            sx={{
              maxWidth: '1012px',
              flex: 1,
            }}
          >
            <FilteredItems
              items={filteredTitles}
              clearClick={title => clearClickHandler(title)}
            />
            <Stack sx={{ mt: '40px' }}>
              <AuctionCardsView view={view} data={buyerData} />
              {count > 1 && (
                <ItemPagination
                  page={page}
                  count={count}
                  onChange={handleChangePage}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  )
}

Auctions.getLayout = page => {
  return <MainLayout>{page}</MainLayout>
}

// As query parameters affect the server-rendered HTML of the page
// We call "getServerSideProps" here, as only statically generated pages have access to query parameters on the client side
export function getServerSideProps() {
  return { props: {} }
}

export default Auctions
