import { Button } from '@mui/material'
import React, { FC } from 'react'

import { Props } from './ChipButton.types'

export const ChipButton: FC<React.PropsWithChildren<Props>> = ({
  Icon,
  children,
  isActionButton,
  ...props
}) => {
  return (
    <Button
      {...props}
      sx={{
        '.MuiButton-startIcon': {
          mr: '6px',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: 'grey.400',
          svg: {
            color: 'grey.400',
          },
        },
        color: 'text.primary',
        p: isActionButton ? '6px 13px 6px 10px' : '6px 10px 6px 13px',
        fontSize: '14px',
        height: '32px',
        border: '1px solid rgba(34, 34, 34, 0.12)',
        fontWeight: 400,
        backgroundColor: 'white',
        '&:hover': {
          borderColor: 'grey.300',
          backgroundColor: 'white',
        },
        '&:active': {
          backgroundColor: 'grey.50',
        },
      }}
      startIcon={isActionButton ? Icon : ''}
      endIcon={!isActionButton ? Icon : ''}
    >
      {children}
    </Button>
  )
}
