import {
  getAuctionLotSize,
  GetAuctionLotSizeResponse,
} from 'api/requests/auction'
import { useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useAuctionLotSizes = (syncWithUrl = false) => {
  const [lotSizesQuery, setLotSizesQuery] = useQueryParam(
    'lotSizes',
    CommaArrayParam
  )

  const [lotSizes, setLotSizes] = useState<GetAuctionLotSizeResponse>()
  const [favoriteLotSizes, setFavoriteLotSizes] = useState<string[]>(() =>
    syncWithUrl ? lotSizesQuery?.map(lotSize => String(lotSize)) ?? [] : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteLotSizes.length === 0) {
      setLotSizesQuery(null)
      return
    }

    setLotSizesQuery(favoriteLotSizes)
  }, [favoriteLotSizes, syncWithUrl])

  const fetchLotSizes = async () => {
    try {
      const response = await getAuctionLotSize()
      if (response?.data) {
        setLotSizes(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleLotSizesSelect = (lotSizeState: string) => {
    let nextFavoriteLotSizes = [...favoriteLotSizes]

    if (nextFavoriteLotSizes?.includes(lotSizeState)) {
      nextFavoriteLotSizes = nextFavoriteLotSizes.filter(
        state => state !== lotSizeState
      )
    } else {
      nextFavoriteLotSizes.push(lotSizeState)
    }
    setFavoriteLotSizes(nextFavoriteLotSizes)
  }

  const handleRestLotSizesSelect = (lotSizesStates: string[]) => {
    let nextFavoriteLotSizes = [...favoriteLotSizes]
    if (nextFavoriteLotSizes?.includes(lotSizesStates[0])) {
      nextFavoriteLotSizes = nextFavoriteLotSizes.filter(
        state => !lotSizesStates.includes(state)
      )
    } else {
      nextFavoriteLotSizes = [...nextFavoriteLotSizes, ...lotSizesStates]
    }
    setFavoriteLotSizes(nextFavoriteLotSizes)
  }

  return {
    lotSizes,
    fetchLotSizes,
    favoriteLotSizes,
    handleLotSizesSelect,
    handleRestLotSizesSelect,
    setFavoriteLotSizes,
  }
}
