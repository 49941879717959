import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { ButtonCustom } from 'components/Button'
import { AuctionCardVertical } from 'components/Card/AuctionCardVertical'
import { StickyNavigation } from 'components/StickyNavigation'
import { FC } from 'react'

export const AuctionsSkeleton: FC = () => {
  return (
    <>
      <StickyNavigation>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          flex={1}
          alignItems={'center'}
        >
          <Box
            sx={{
              width: {
                xs: 'auto',
                md: '22%',
              },
              mr: '2.2%',
            }}
          >
            <Skeleton>
              <Box display='flex' alignItems='center'>
                <Box
                  sx={{
                    padding: '0 8px',
                    mr: '12px',
                  }}
                >
                  <Typography variant={'h3'}>{1}</Typography>
                </Box>
                <Typography variant={'h3'}>{'title'}</Typography>
              </Box>
            </Skeleton>
          </Box>
          <Stack
            direction={'row'}
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: '1012px',
              flex: 1,
            }}
          >
            <Skeleton
              variant={'rectangular'}
              sx={{
                width: '100%',
                minWidth: '258px',
                maxWidth: '670px',
                height: '48px',
                borderRadius: '24px',
              }}
            />
            <Stack direction={'row'} spacing={'12px'}>
              <ButtonCustom loading height={'48px'} sx={{ width: '117px' }} />
              <ButtonCustom loading height={'48px'} sx={{ width: '103px' }} />
            </Stack>
          </Stack>
        </Stack>
      </StickyNavigation>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '22%',
            mr: '2.2%',
          }}
        >
          <Box sx={{ m: '24px 0 26px' }}>
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'80%'} height={'40px'} />
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'20%'} sx={{ mt: '9px' }} />
          </Box>
          <Box sx={{ m: '24px 0 26px' }}>
            <Skeleton
              width={'30%'}
              variant={'text'}
              sx={{
                mb: '16px',
              }}
            />
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'80%'} height={'40px'} />
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'20%'} sx={{ mt: '19px' }} />
          </Box>
          <Box sx={{ m: '24px 0 26px' }}>
            <Skeleton
              width={'30%'}
              variant={'text'}
              sx={{
                mb: '16px',
              }}
            />
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'80%'} height={'40px'} />
            <Skeleton variant={'text'} width={'70%'} height={'40px'} />
            <Skeleton variant={'text'} width={'50%'} height={'40px'} />
            <Skeleton variant={'text'} width={'20%'} sx={{ mt: '19px' }} />
          </Box>
        </Box>
        <Stack
          sx={{
            maxWidth: '1012px',
            flex: 1,
          }}
        >
          <Stack spacing={'56px'} sx={{ mt: '40px' }}>
            <Grid container spacing={'16px'}>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <AuctionCardVertical
                  status={'title'}
                  title={'title'}
                  address={'title'}
                  condition={'title'}
                  packages={[]}
                  totalBids={2}
                  hasReservePrice={true}
                  isReservePriceMet={true}
                  myMaxBid={1}
                  isMyBidHighest={true}
                  startingBidPrice={0}
                  currentBidPrice={0}
                  winningBidPrice={0}
                  itemCount={1}
                  totalWeight={1}
                  endDate={new Date()}
                  loading={true}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
